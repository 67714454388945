<template>
  <p class="clearfix mb-0">
    <span class="float-md-right d-none d-md-block">Version 1.2.2
    </span>
  </p>
</template>

<script>
import { BLink } from 'bootstrap-vue'

export default {
  components: {
    BLink,
  },
}
</script>
